a {
    text-decoration: none;
  }
  
  .login-body {
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    align-items: center;
    padding: 200px 0px;
  }
  
  .box {
    border: 1px solid #dfdfdf;
    background-color: #fff;
    padding: 20px;
  }
  
  .g-btn {
    width: 100% !important;
    height: 40px !important;
    line-height: 40px !important;
    font-size: 15px !important;
  }
  .g-btn > div,
  .g-btn > div > svg {
    width: 40px !important;
    height: 38px !important;
  }
  .button-right {
    display: flex;
    justify-content: flex-end;
  }

  .row-spacer {
    margin-bottom: 20px;
  }

  .button-center {
    display: flex;
    justify-content: center;
  }
  
  #recaptcha-container {
    margin: 20px;
  }