.edit-vendor-wrapper {
    flex-direction: column;
    width: 40%;
    height: auto;
    max-height: calc(100vh - 210px);
    overflow-y: auto;
    background-color: white;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 40px 50px;

    form {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        justify-content: space-around;
        padding: 0px 0px;
  
        .formInput {
            width: 100%;
            label {
                display: flex;
                align-items: center;
                gap: 10px;
    
                .icon {
                cursor: pointer;
                }
            }
  
            input {
                width: 100%;
                padding: 5px 0px;
                border: none;
                border-bottom: 1px solid gray;
               // margin-bottom: 30px;
            }
        }

        .edit-modal-error-message-wrap {
            background-color: #ab4855;
            color: white;
            padding: 3px 20px;
            border-radius: 5px;
        }

        .edit-modal-button-row {
            display: flex;
            gap: 20px;
        }
  
        button {
            justify-content: center;
            align-items: center;
            display: flex;
            width: 150px;
            border: none;
            background-color: teal;
            color: white;
            font-weight: bold;
            cursor: pointer;

            
        }
        .disabled {
            cursor: default;
            background-color: grey;
        }
      }
}

.dropzone {
    margin: 10px 20px;
    padding: 0px 20px;
    
}

    
