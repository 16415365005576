.upload-menu-item-csv-wrapper {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 600px;
    background-color: white;
    //color: white;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #ccc;
    border-radius: 10px;
}

.upload-instructions {
    margin: 5px 20px;
    text-align: left;
}

.dropzone {
    background: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    padding: 20px;
    cursor: pointer;
    height: 150px;
    width: 400px;
    border: 2px dashed cyan;
    outline: none;
  }
  
  .dropzoneReject {
    border: 2px dashed red;
  }
  
  .dropzoneAccept {
    border: 2px dashed green;
  }