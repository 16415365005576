.vendor-info-wrapper {
    height: 600px;
    padding: 20px;

    .vendor-info-title{
      width: 100%;
      font-size: 24px;
      color: gray;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
}