.home {
    display: flex;
  
    .homeContainer {
      flex: 6;
  
      .widgets,
      .charts {
        display: flex;
        padding: 20px;
        gap: 20px;
      }
  
      .charts {
        padding: 5px 20px;
      }
  
      .listContainer {
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        margin: 20px;
  
        .listTitle {
          font-weight: 500;
          color: gray;
          margin-bottom: 15px;
        }
      }
    }
    
  }

  .margin-spacer {
    margin-bottom: 20px;
  }
  
  .select-vendor-title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .vendor-logout-button {
    background-color: #2A3841;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }

  .vendor-select-button {
    background-color: #844454;
    color: #fff;
    padding: 5px 20px;
    border-radius: 5px;
    cursor: pointer;
  }

  .vendor-select-button-created {
    background-color: #2A3841;
    color: #fff;
    padding: 5px 20px;
    border-radius: 5px;
    cursor: pointer;
  }

  .select-vendor-wrapper {
    background-color: #fafafa;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    align-items: center;
    height: 70vh;
  }
