.filename-div {
    font-size: 18px;
    font-weight: bold;
    margin: 5px 20px;

    .filename-div-name {
        font-weight: normal
    }
}

        .edit-modal-button-row {
            display: flex;
            gap: 20px;
        }
  
        button {
            margin-top: 30px;
            justify-content: center;
            align-items: center;
            display: flex;
            width: 150px;
            border: none;
            background-color: teal;
            color: white;
            font-weight: bold;
            cursor: pointer;
        }
        .disabled {
            cursor: default;
            background-color: grey;
        }

.dropzone {
    margin: 30px 20px;
    padding: 0px 20px;
    
}

    
