.redeem-success {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: auto;
  max-height: 85%;
  background-color: green;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 40px 50px;
  .redeem-success-h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  .redeem-success-h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  .redeem-success-button {
    background-color: white;
    color: green;
    border: none;
    padding: 10px 60px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 2rem;
  }
}

.redeem-error {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: auto;
  max-height: 85%;
  background-color: red;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 40px 50px;
  .redeem-error-button {
    background-color: white;
    color: red;
    border: none;
    padding: 10px 60px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 2rem;
  }
}