.drink-details-wrapper {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: auto;
    background-color: white;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 40px 50px;

    .drink-details-heading {
        font-size: xx-large;
        margin-bottom: 15px;
        font-weight: bold;
    }

    .drink-detail {
        display: flex;
        width: 100%;
        margin-bottom: 5px;

        .drink-detail-title {
            display: flex;
            justify-content: right;
            flex-grow: 1;
            width:100%
        }
        .drink-detail-data {
            display: flex;
            flex-grow: 1;
            justify-content: left;
            margin-left: 10px;
            width: 100%;
        }
        ul {
            list-style-type: none;
            margin: 0px;
            padding: 0px;
        }
    }
}

    
