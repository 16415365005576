.landing-wrap {
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.landing-top-nav {
    display: flex;
    flex-direction: row;
    height: 100px;
    width: 100%;
    //background-color: #2A3841;
    background-color: #111111;
    color: #ddd;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .landing-logo {
        width: 120px;
        height: 120px;
        img {
            max-height:120px;
        }
    }
    .landing-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        .landing-nav-item {
            display: flex;
            margin: 0px 20px;
            //font-size: 24px;
            cursor: pointer;

            .link-text {
                color: #ddd;
            }
        }
    }
    .landing-nav.open {
        display: flex;
    }

    /* Hamburger menu icon styling */
    .hamburger-menu {
        display: none;
        cursor: pointer;
        font-size: 1.5rem;
        color: white;
    }

    @media (max-width: 768px) {
        .landing-nav {
            position: absolute;
            top: 80px;
            right: 0;
            background-color: #333;
            width: 100%;
            display: none;
            flex-direction: column;
            align-items: center;
            transform: translateY(-100%);
            transition: transform 0.3s ease;
            z-index: 1;

            &.open {
                display: flex;
                transform: translateY(0);
            }

            .landing-nav-item {
                padding: 15px;
                border-bottom: 1px solid #555;
                width: 100%;
                text-align: center;

                .link-text {
                    font-size: 1.2rem;
                }
            }
        }

        .hamburger-menu {
            display: block; /* Display hamburger icon on small screens */
        }
    }
}

.app-download-links {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    justify-content: space-between;
}


.landing-bannerImage {
    width: 100%;
    height: 600px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.landing-title {
    color: white;
    font-size: 72px;
    font-weight: bold;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .landing-title {
        font-size: 48px;
    }
    .app-download-links {
        flex-direction: column;
        gap: 20px;
    }
}

@media (max-width: 768px) {
    .landing-description {
        font-size: 18px;
        color: red
    }
}

.landing-description {
    color: white;
    font-size: 32px;
}
.landing-action-buttons {
    display: flex;
    flex-direction: row;
}
.landing-action-button {
    background-color: #844454;
    color: #ddd;
    padding: 20px 30px;
    margin: 50px 20px;
    border-radius: 50px;
    font-size: 24px;
    cursor: pointer;
    width: 250px;
    text-align: center;
}

.landing-descriptive-container {
    width: 80%;
    margin-top: 100px;
    margin-left: 10%;
    padding: 80px 0;
    background-color: #fff;
    display: flex;
    align-items: center;

    .landing-descriptive-wrap {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .landing-descriptive-body-wrap {
            display: flex;
            flex-direction: column;
            padding-right: 20px;

            .landing-descriptive-body-title {
                font-size: 72px;
                margin-bottom: 20px;
                font-weight: bold;
            }

            .landing-descriptive-body-subhead {
                font-size: 48px;
                margin-bottom: 20px;

                .make-italic {
                    font-style: italic;
                }
            }
            .landing-descriptive-body-text {
                margin-bottom: 20px;
                font-size: 24px;
            }
            .app-download-links-left {
                margin-top: 30px;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 30px;
                justify-content: flex-start;
            }
        }

        .landing-descriptive-body-image {
            display: flex;
            img {
                max-width:100%;
                border-radius: 50px;
            }
        }

        .landing-vendor-body-image {
            display: flex;
            img {
                max-width:100%;
                border-radius: 20px;
                transition: transform 0.3s;
            }
            margin-right: 30px;
        }
        .landing-vendor-body-image:hover img {
            transform: scale(1.05);
        }

        .landing-vendor-body-image-mobile {
            display: none;
            // img {
            //     max-width:50%;
            //     border-radius: 20px;
            // }
            // margin-right: 30px;
        }
    }

    @media (max-width: 768px) {
        .landing-descriptive-wrap {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 0px;
    
            .landing-descriptive-body-wrap {
                padding-right: 5px;
                margin: 0px 10px;
                width: 95%;
                display: flex;
                flex-direction: column;
                align-items: center;
    
                .landing-descriptive-body-title {
                    width: 100%;
                    font-size: 32px;
                    margin-bottom: 5px;
                    display: flex;
                }
    
                .landing-descriptive-body-subhead {
                    font-size: 24px;
                    margin-bottom: 20px;
                    display: flex;
    
                    .make-italic {
                        font-style: italic;
                    }
                }
                .landing-descriptive-body-text {
                    margin-bottom: 20px;
                    margin: 10px 10px 0px 10px;
                    font-size: 18px;
                }
                .app-download-links-left {
                    margin-top: 30px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 30px;
                    justify-content: flex-start;
                }
            }
    
            .landing-descriptive-body-image {
                display: flex;
                img {
                    max-width:100%;
                    border-radius: 50px;
                }
            }
    
            .landing-vendor-body-image {
                display: none;
                // img {
                //     max-width:100%;
                //     border-radius: 20px;
                // }
                // margin-right: 30px;
            }
            .landing-vendor-body-image-mobile {
                display: flex;
                img {
                    max-width:50%;
                    border-radius: 20px;
                    margin: 10px auto;
                }
                margin-right: 30px;
            }
        }
        .landing-vendor-body-image {
            display: none;
            // img {
            //     max-width:100%;
            //     border-radius: 20px;
            // }
            // margin-right: 30px;
        }
    }
}

@media (max-width: 768px) {
    .landing-descriptive-container {
        width: 95%;
        margin-top: 20px;
        margin-left: 0px;
    }
}

@media (max-width: 768px) {
    .landing-steps-container {
        margin-top: 10px;

    }
}

.landing-steps-container {
    padding: 60px 0;
    background-color: #282828;
    color: #fff;
    margin-top: 80px;

    .landing-steps-title {
        font-size: 48px;
        margin-bottom: 30px;
        animation: fadeIn 1.5s;
        text-align: center;
    }

    .landing-steps-steps-container {
        .landing-steps-steps {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            gap: 20px;
            margin: 0 10px;

            .landing-steps-item {
                background-color: rgba(255, 255, 255, 0.1);
                padding: 20px;
                border-radius: 10px;
                transition: transform 0.3s;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
                width: 80%;

                &:hover {
                    transform: translateY(-10px);
                }

                .landing-steps-item-icon {
                    width: 80px;
                    margin-bottom: 20px;
                    align-items: center;
                    justify-content: center;
                    img {max-width:100%;}
                }

                .landing-steps-item-title-small {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .landing-steps-item-title {
                        font-size: 24px;
                        margin-bottom: 20px;
                    }

                    .landing-steps-item-description {
                        text-align: center;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .landing-steps-title {
            font-size: 32px;
            text-align: center;
        }

        .landing-steps-steps-container {
            //width: 80%;
            .landing-steps-steps {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .landing-steps-item {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    //margin: 20px;
                    flex: 1;

                //     background-color: rgba(255, 255, 255, 0.1);
                // padding: 20px;
                // border-radius: 10px;
                // transition: transform 0.3s;
                // display: flex;
                // flex-direction: column;
                // align-items: center;
                // gap: 10px;
                width: 100%;
               

                    .landing-steps-item-icon {
                        width: 80px;
                        margin-right: 10px;
                        flex: 1
                        img {max-width:100%;}
                    }
                    .landing-steps-item-title-small {
                        display: flex;
                        flex: 3;
                        flex-direction: row;
                        .landing-steps-item-title {
                            font-size: 24px;
                            margin-bottom: 5px;
                        }
    
                        .landing-steps-item-description {
                            text-align: left;
                            line-height: 24px;
                        }
                    }
                    
                }
            }
        }
    }
}

.landing-footer-container {
    height: 400px;
    background-color: #2A3841;
    color: #ddd;
}

.landing-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-height: 100%;
}
