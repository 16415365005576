.notfound {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;
    height: 500px;
}

.linkBackHome {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
}