.edit-details-item-wrapper {
    display: flex;
    flex-direction: column;
    width: 96%;
    height: auto;
    max-height: 85%;
    background-color: white;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 40px 50px;

    form {
        width: 40%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 30px;
        justify-content: space-around;
        padding: 0px 0px;
  
        .formInput {
            width: 100%;
            label {
                display: flex;
                align-items: center;
                gap: 10px;
    
                .icon {
                cursor: pointer;
                }
            }
  
            input {
                width: 100%;
                padding: 5px 0px;
                border: none;
                border-bottom: 1px solid gray;
               // margin-bottom: 30px;
            }
        }

        .edit-modal-error-message-wrap {
            background-color: #ab4855;
            color: white;
            padding: 3px 20px;
            border-radius: 5px;
        }

        .edit-modal-button-row {
            display: flex;
            gap: 10%;
            margin-left: -20px;
            justify-content: center;
        }
  
        
    }
    @media (max-width: 600px) {
        form {
            width: 90%;
            margin-top: 10px;
            padding: 0px;

            
        }
        .edit-modal-button-row {
            flex-direction: column;
        }
    }
}

.datpicker-button {
    button {
        width: auto;
    }
}

    
